* {
  font-family: Muli, sans-serif;
  color: #333;
}

body {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  background-color: #fff;
}

.Toast__toast___XXr3v{
  background-color: #ffd862 !important;
  font-weight: 600;
}

#flashDiv {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  box-shadow: 0 0 5px 0 #ffd862 ;
  padding: 16px;
  background-color: #ffd862 !important;
  font-weight: 600;
  font-size: 14px;
  box-sizing: border-box;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col-2,
.col-10,
.col {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex: 0 0 100%;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.66666%;
  max-width: 16.66666%;
}

.col-3 {
  flex: 0 0 24.99999%;
  max-width: 24.99999%;
}

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  flex: 0 0 41.666666%;
  max-width: 41.666666%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  flex: 0 0 66.66666%;
  max-width: 66.66666%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  flex: 0 0 91.666666%;
  max-width: 91.666666%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66666%;
}

@media (min-width: 576px) {
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 25%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-8 {
    flex: 0 0 66.66666%;
    max-width: 66.66666%;
  }

  .offset-sm-2 {
    margin-left: 16.66666%;
  }

  .offset-sm-3 {
    margin-left: 24.99999%;
  }

  .offset-sm-4 {
    margin-left: 33.33333%;
  }
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.mt-30 {
  margin-top: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}


.input, .Input__input___3e_bf {
  width: 100% !important;
  height: 38px !important;
  margin-bottom: 15px !important;
  font-size: 14px !important;
  line-height: 1.42857143 !important;
  color: #333333 !important;
  vertical-align: middle !important;
  background-color: rgba(79, 94, 120, 0.15) !important;
  border-radius: 0px !important;
  border-style: none none solid !important;
  border-width: 1px !important;
  border-radius: 3px !important;
  border-color: rgb(77, 144, 254) !important;
  outline: none !important;
  padding: 0 10px !important;
  -moz-box-sizing: border-box !important;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
  position: relative !important;
}

input:focus, .Input__input___3e_bf:focus {
  border-style: solid solid !important;
  border-color: rgb(77, 144, 254) !important;
}

input::placeholder, .Input__input___3e_bf::placeholder {
  color: #828282;
}

input:-ms-input-placeholder, .Input__input___3e_bf:-ms-input-placeholder {
  color: #828282;
}

input::-ms-input-placeholder, .Input__input___3e_bf::-ms-input-placeholder {
  color: #828282;
}

.fa-eye,
.fa-eye-slash {
  color: #00205b;
  position: absolute;
  font-size: 17px;
  right: 7%;
  top: 46%;
}

.password-icon{
  top: 57%;
}

.pass-signup{
  top: 36.5%;
}

.confirm-signup{
  top: 59.5%;
}

.spinner {
  border: 0.25em solid rgba(255, 255, 255, 0.8);
  border-left-color: transparent;
  border-radius: 50%;
  width: 2em;
  height: 2em;
  animation: spin 0.7s linear infinite;
}

@keyframes spin {
  to {
      transform: rotate(360deg);
  }
}

.checkbox-label {
  display: inline-block;
  position: relative;
  margin: auto;
  cursor: pointer;
  font-size: 22px;
  line-height: 24px;
  height: 12px;
  clear: both;
  margin-right: 20px;
}

.checkbox-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkbox-label .checkbox-custom {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 12px;
  width: 12px;
  background-color: transparent;
  border-radius: 2px;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  border: 1px solid #3898ec;
}

.checkbox-label input:checked ~ .checkbox-custom {
  background-color: #3898ec;
  border-radius: 2px;
  -webkit-transform: rotate(0deg) scale(1);
  -ms-transform: rotate(0deg) scale(1);
  transform: rotate(0deg) scale(1);
  opacity:1;
  border: 1px solid #3898ec;
  box-shadow: 0px 0px 3px 1px #3898ec;
}

.checkbox-label .checkbox-custom::after {
  position: absolute;
  content: "";
  left: 12px;
  top: 12px;
  height: 0px;
  width: 0px;
  border-radius: 2px;
  border: solid #fff;
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(0deg) scale(0);
  -ms-transform: rotate(0deg) scale(0);
  transform: rotate(0deg) scale(0);
  opacity:1;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
}

.checkbox-label input:checked ~ .checkbox-custom::after {
-webkit-transform: rotate(45deg) scale(1);
-ms-transform: rotate(45deg) scale(1);
transform: rotate(45deg) scale(1);
opacity: 1;
left: 4px;
top: 1px;
width: 3px;
height: 7px;
border: solid #fff;
border-width: 0 2px 2px 0;
background-color: transparent;
border-radius: 0;
}

.banner-login {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  background-image: url("./assets/images/background.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.cardMiddle {
  position: relative;
  color: #ffffff;
  padding: 0 15px;
  margin: 10% auto;
  box-shadow: 0 3px 20px -1px #000;
}

.cardMiddle.no-height {
  height: auto;
}

.cardMiddle .formSection {
  margin: 30px auto 0;
  text-align: center;
}

@media (max-width: 500px) {
  .col-6 {
    width: 100%;
    flex: 100%;
    max-width: 100%;
  }
  
  .card-image {
    width: auto !important;
    height: auto !important;   
    border-radius: 0 !important; 
  }

  .title-logo{
    padding: 20px 10px !important;
    text-align: center;
  }

  .card-login {
    height: 400px !important;
    border-bottom-left-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
    border-radius: 0 !important; 
    padding: 0 10px  !important;
  }

  .card-signup {
    height: 400px !important;
    border-bottom-left-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
    border-radius: 0 !important; 
    padding: 10px  !important;
  }

  .card-confirm {
    height: 400px !important;
    border-bottom-left-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
    border-radius: 0 !important; 
    padding: 0 30px  !important;
  }

  .fa-eye, .fa-eye-slash {
    top: 66% !important;
  }

  .password-icon{
    top: 57% !important;
  }
  
  .pass-signup{
    top: 62% !important;
  }
  
  .confirm-signup{
    top: 70% !important;
  }

  .card-forgotpassword {
    height: 400px !important;
    border-bottom-left-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }

  input {
    font-size: 12px !important;
  }

  a {
    white-space: nowrap;
    font-size: 12px !important;
  }

  .btn {
    font-size: 10px;
  }

  .forgot-password {
    margin-right: 10px !important;
  }
}

@media (min-width: 600px) {
   
}

.cardMiddle h1 {
  color: #ffffff;
  font-size: 30px;
  font-weight: bold;
  position: relative;
  margin-bottom: 50px;
}

.cardMiddle h1:before {
  content: "";
  width: 50px;
  height: 1px;
  background: #fff;
  position: absolute;
  bottom: -10px;
  left: 50%;
  -moz-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

@media (min-width: 768px) {
  .cardMiddle h1 {
    font-size: 45px;
  }
}

.cardMiddle .description {
  margin-bottom: 30px;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
}

.cardMiddle .question {
  color: #828282;
  font-size: 12px;
}

.cardMiddle .steps {
  text-align: center;
  -moz-transform: translateY(10%);
  -webkit-transform: translateY(10%);
  -o-transform: translateY(10%);
  -ms-transform: translateY(10%);
  transform: translateY(10%);
}

@media (max-width: 767px) {
  .cardMiddle .steps {
    display: none;
  }
}

.cardMiddle .steps .step {
  width: 44px;
  height: 44px;
  border: 1px solid var(--amazonOrange);
  color: var(--amazonOrange);
  display: inline-block;
  font-size: 30px;
  font-weight: bold;
  line-height: 44px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.cardMiddle .steps .step.active {
  background: var(--amazonOrange);
  color: #2c2c2c;
}

.cardMiddle .steps .separator {
  height: 40px;
  background: #a0a0a0;
  width: 1px;
  margin: 5px auto;
}

.card-login, .card-signup, .card-confirm {
  height: 500px;
  max-width: 100%;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0px 3px 3px 0px;
  background-color: #fff;
  padding: 0 50px;
}

.card-forgotpassword{
  height: 500px;
  border-radius: 6px;
  background-color: #fff;
  display: flex;
  flex-direction: column;  
  padding: 0 50px;
  box-sizing: border-box; 
}


.card-image {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 400px;
  height: 500px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(rgba(32, 19, 19, 0.67)),
      to(rgba(32, 19, 19, 0.67))
    ),
    url("./assets/images/card.jpg");
  background-image: linear-gradient(180deg, rgba(32, 19, 19, 0.67), rgba(32, 19, 19, 0.67)),
    url("./assets/images/card.jpg");
  background-size: auto, cover;
  border-radius: 3px 0px 0px 3px;
}

.card-forgotpassword {
  height: 500px;
  border-radius: 6px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title-logo {
  padding-bottom: 10%;
  padding-left: 10%;
}

.title-logo img {
  margin-bottom: 20px;
  width: 220px;
}

.title-signin {
  color: #000;
  margin-bottom: 30px;
}

.text-image {
  color: #fff;
  font-size: 14px;
}

.text-checkbox, .text-checkbox-terms{
  color: #666e7a;
  font-size: 14px;
  font-weight: 400;
}

.text-checkbox-terms{
  text-decoration: none;
}

.text-checkbox-terms:hover{
  text-decoration: underline;
}

.center {
  text-align: center;
}

.forgot-password {
  margin-right: 20%;
}

.btn-create {
  height: auto;
  margin-top: 30px;
  padding: 13px 48px;
  border: 1px solid #fff;
  border-radius: 30px;
  background-color: transparent;
  -webkit-transition: color 500ms ease, background-color 500ms ease, -webkit-transform 500ms ease;
  transition: color 500ms ease, background-color 500ms ease, -webkit-transform 500ms ease;
  transition: transform 500ms ease, color 500ms ease, background-color 500ms ease;
  transition: transform 500ms ease, color 500ms ease, background-color 500ms ease, -webkit-transform 500ms ease;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  cursor: pointer;
  outline: none;
}

.btn-create:hover {
  background-color: rgba(0, 176, 230, 0.25);
}

.btn-signin {
  margin: 20px 0;
  width: auto;
  height: auto;
  padding: 13px 48px;
  justify-content: center;
  border-radius: 30px;
  background-color: #ffd862;
  transition-property: none;
  color: #002c63;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 1.5px;
  outline: none;
  border: 1px solid #ffd862;
  text-transform: uppercase;  
  cursor: pointer;
}

.btn-signin:disabled {
  cursor: no-drop !important;
  background-color: #fdda70;
}

.btn-signin:hover {
  background-color: #fdda70;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.reset-password, .resend-code {
  margin-right: 0px;
  color: #000;
  font-size: 12px;
  text-align: right;
  text-decoration: underline;
  cursor: pointer;
}

.error {
  font-size: 12px;
  color: red;
  margin-top: -15px;
  margin-bottom: 10px;
}

.description-password {
	font-size: 12px;
	padding: 0px 0px 5px 0px;
}