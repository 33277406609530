:root {
    --amplify-primary-color: #ff9900;
}
#root {
    margin: 0 auto;
    width: 800px;
}

@media (max-width: 500px) {
    #root {
        width: auto;
        padding: 0 10px;
    }
}

@media (max-width: 768px) {
    #root {
        width: auto;
        padding: 0 10px;
    }
}

h1.title {
    text-transform: uppercase;
    font-family: Arial;
    font-size: 72px;
    text-align: center;
}
a.admin-button {
    background-color: var(--amplify-tertiary-color);
    width: 90%;
    display: block;
    padding: 20px;
    margin: 0 auto;
    text-align: center;
    color: var(--amplify-secondary-contrast);
    text-decoration: none;
    text-transform: uppercase;
    font-family: Arial;
    font-size: 12px;
    cursor: pointer;
}
